import React from "react";
import ReactDOM from "react-dom";
import OnlineAgentsDropdown from "./components/OnlineAgentsDropdown";

export default class EventWatcher extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            container: null,
            value: null,
        };

        this.handleEvent = this.handleEvent.bind(this)
        this.wrapper = React.createRef()
    }

    handleEvent(ev) {
        const value = this.eventCb(this, ev)
        this.setState({value})
    }

    render() {
        return (
            <span ref={this.wrapper} dangerouslySetInnerHTML={{__html: this.state.value}} />
        )
    }

    componentDidMount() {
        // register eventlistener
        const container = this.wrapper.current.parentElement;

        // eslint-disable-next-line no-eval
        this.eventCb = eval(container.dataset.onevent);
        // eslint-disable-next-line no-eval
        this.changeCb = eval(container.dataset.onchange);
        this.default = container.dataset.default;
        this.eventName = container.dataset.event;

        this.setState({container, value: this.default})

        if (this.eventCb && this.eventName) {
            document.addEventListener(this.eventName, this.handleEvent)
        } else {
            const value = "Required properties: data-onevent, data-event. Optional properties: data-onchange, data-default"
            this.setState({value})
        }
    }

    componentWillUnmount() {
        // unregister eventlistener
        document.removeEventListener(this.eventName, this.handleEvent)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.value !== this.state.value) {
            if (this.changeCb) {
                this.changeCb(this, this.state.value)
            }
        }
    }
}

if (document.getElementsByClassName("event-watcher").length > 0) {
    Array.from(document.getElementsByClassName("event-watcher")).forEach(el =>
        ReactDOM.render(<EventWatcher/>, el)
    )
}

if (document.getElementsByClassName("online-agents-dropdown").length > 0) {
    Array.from(document.getElementsByClassName("online-agents-dropdown")).forEach(el =>
        ReactDOM.render(<OnlineAgentsDropdown/>, el)
    )
}
