import React from "react";
import TimeAgo from 'javascript-time-ago'
import de from 'javascript-time-ago/locale/de'
import ReactTimeAgo from "react-time-ago";

TimeAgo.addDefaultLocale(de)

export default class OnlineAgentsDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeAgents: [],
            initial: true,
        }

        this.setActiveAgents = this.setActiveAgents.bind(this);
    }

    render() {
        const onlineAgents = this.state.activeAgents.length;
        return (
            <div className="dropdown">
                <button className={`dropdown-toggle btn btn-sm btn-outline-secondary ${!onlineAgents ? "disabled" : ""}`} data-bs-toggle="dropdown">
                    {this.state.initial
                        ? <span>???</span>
                        : <span>{onlineAgents} Agent{onlineAgents === 1 ? "" : "en"} Online</span>
                    }

                </button>
                <ul className="dropdown-menu">
                    {this.state.activeAgents.map(agent =>
                        <li className="dropdown-item small" key={agent.user__username}>
                            <div className="d-flex justify-content-between">
                                <div className="me-2 fw-bold">{agent.user__first_name || agent.user__last_name ? agent.user__first_name + " " + agent.user__last_name : agent.user__username}</div>
                                <div className="text-muted"><ReactTimeAgo date={agent.last_seen*1000} locale="de-AT" /></div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        )
    }

    setActiveAgents(ev) {
        console.log(ev.detail);
        this.setState({activeAgents: ev.detail.message, initial: false})
    }

    componentDidMount() {
        document.addEventListener("ws-message.chat.agents", this.setActiveAgents);
    }

    componentWillUnmount() {
        document.removeEventListener("ws-message.chat.agents", this.setActiveAgents);
    }
}
